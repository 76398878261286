import moment from 'moment';

const params_h24 = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h24',
};

const params_h12 = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h12',
};

const toLocaleDateString = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString('en-CA', params_h24);
  }

  return new Date().toLocaleDateString('en-CA', params_h24);
};

export class UtilsDate {
  constructor(date) {
    if (typeof date !== 'number' && date) {
      this.date = date ? new Date(date) : new Date();
      return;
    }

    this.date = date;
  }

  dateToUnix() {
    if (this.date) {
      this.date = Math.floor(this.date / 1000);
      return this.date;
    }
  }

  unixToDate() {
    if (this.date) {
      this.date = new Date(this.date * 1000);
      return this.date;
    }
  }

  unixToInputDateTime() {
    const date = toLocaleDateString(this.date * 1000);
    return date.replace(', ', 'T');
  }

  unixToInputDate() {
    const date = toLocaleDateString(this.date * 1000);
    return date.split(' ')[0];
  }

  unixToInputTime() {
    const date = toLocaleDateString(this.date * 1000);
    return date.split(' ')[1];
  }

  unixToLocaleDateString(params = { options: {} }) {
    if (this.date) {
      const opts = {
        locales: 'en-GB',
        options: { day: '2-digit', month: '2-digit', year: 'numeric' },
      };

      if (params.options.withTime) {
        opts.options.hour = '2-digit';
        opts.options.minute = '2-digit';
        opts.options.hourCycle = 'h12';
      }

      const { locales, options } = opts;
      this.date = new Date(this.date * 1000);
      return this.date
        .toLocaleString(locales, options)
        .toUpperCase()
        .replace(',', '');
    }
  }
}

export const nowToLocaleDateString = toLocaleDateString().replace(', ', 'T');

export function unixToDisplayDateTime(date) {
  let unixToDate = new Date(date * 1000);
  unixToDate = unixToDate.toLocaleDateString('en-GB', params_h12);
  unixToDate = unixToDate.toUpperCase();
  unixToDate = unixToDate.replace(',', '');

  return date ? unixToDate : '';
}

export function unixToDisplayTime(date) {
  let unixToTime = new Date(date * 1000);
  unixToTime = unixToTime.toLocaleTimeString('en-GB', params_h12);
  unixToTime = unixToTime.toUpperCase();

  return date ? unixToTime : '';
}

export function addToTime(date, value) {
  const getTime = new Date(date * 1000).getTime();
  return new Date(getTime + (value / 60) * 60 * 60 * 1000) / 1000;
}

export function addToDate(value, status) {
  return toLocaleDateString(
    moment(new Date()).add(value, status).toDate()
  ).replace(', ', 'T');
}
